import { graphql, Link } from 'gatsby';
import get from 'lodash/get';
import React from 'react';
import HeaderImage from 'components/HeaderImage';

import { siteMetadata } from '../../gatsby-config';
import Layout from 'components/Layout';
import Meta from 'components/Meta';

const OrganizationsPage = ({ location, data }) => {
  const header = get(data, 'header.childImageSharp.fluid');

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="For Organizations" />
      <HeaderImage image={header}>
        <h1>For Organizations</h1>
      </HeaderImage>

      <p>
        <strong className="notice">Coming soon.</strong>
      </p>
      <p>
        We're focused on individuals during our launch, but if there's something
        you'd like to ask for your organization, we're happy to talk!
      </p>

      <h2>Working with us</h2>

      <p>
        To work with us, just send a message. We'll set up an initial meeting,
        free of charge, to get to know each other and see if we can help.
      </p>

      <Link to="/contact" className="btn btn-primary btn-lg">
        Get in touch!
      </Link>
    </Layout>
  );
};

export default OrganizationsPage;

export const query = graphql`
  query OrganizationsPageQuery {
    header: file(name: { eq: "dylan-nolte-576808-unsplash" }) {
      childImageSharp {
        fluid(maxWidth: 800, grayscale: true, cropFocus: SOUTH) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
